
import PageHeader from '@/components/PageHeader.vue'
import tableMixin from '@/minxin/table-minxin'
import Steps from '@/components/steps'
import axios from 'axios'
import $post from '@/request/productApis.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import root from '@/minxin/root'
// /引入表格拖拽依赖
import Sortable from 'sortablejs'
// import { toPrecision } from 'core-js/fn/number/epsilon'
const productList = {
  name: 'ProductList',
  components: {
    PageHeader,
    Steps,
    ElImageViewer
    // searchView
  },
  mixins: [tableMixin, root],
  watch: {
    // 留言内容监听
    input_value(a) {
      this.sendMsgForm.Message = a
      this.chat_value_length = a.length
    },
    selects(a) {
      this.selectsArr = []
      if (a.length === 0) return this.selectsArr = ''
      a.map(item => {
        this.selectsArr.push(item.IDX)
      })
      console.log(this.selectsArr)
    }
  },
  data() {
    return {
      root_name: 'ppsy',
      root_msg: '产品首营列表',
      selects: [],
      selectsArr: [],
      disabled: true,
      chat_value_length: 0,
      formJson: {},
      dataJson: {},
      showElement: false,
      UserInfo: {}, // 人员信息
      keywords: '',
      stepsData: [
        {
          id: '1',
          name: '新增商品',
          icon: 'el-icon-document-add'
        },
        {
          id: '2',
          name: '完善商品信息',
          icon: 'el-icon-edit'
        },
        {
          id: '3',
          name: '上传商品证照',
          icon: 'el-icon-upload'
        },
        {
          id: '4',
          name: '等待审核',
          icon: 'el-icon-s-promotion'
        }
      ],
      input2: '',
      input3: '',
      value: '',
      // 行高改变的一些字段
      targetTd: null,
      coltargetTd: null,
      resizeable: false,
      mousedown: false,
      // 裁剪组件的基础配置option
      option: {
        view_image: '',
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      options: [],
      dialogTableVisible: false,
      materialdetaisinfo: {
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeName: '',
        InvTypeName: '',
        BrandID: '',
        Specification: '',
        StockUnitN: '',
        MinPacking: '',
        RegisterNo: '',
        RegisterStart: '',
        RegisterEnd: '',
        ProductFactory: '',
        ShelfLifeNum: '',
        ShelfLifeTypeStr: '',
        TransportCondition: '',
        ProductLicenseNo: '',
        SourceAreaName: '',
        StorageNo: ''
      },
      value1: '',
      active: 0,
      rules: {
        ApplyDate: [
          { required: true, message: '请选择申请日期', trigger: 'blur' }
        ],
        MaterialName: [
          { required: true, message: '请输入物料名称', trigger: 'blur' }
        ],
        InvCode: [
          { required: true, message: '请输入物料编号', trigger: 'blur' }
        ],
        RegisterNo: [
          { required: true, message: '请输入注册证号', trigger: 'blur' }
        ],
        RegisterEnd: [
          { required: true, message: '请选择注册证截止日期', trigger: 'blur' }
        ],
        Tax: [
          { required: true, message: '请输入产品税率', trigger: 'blur' }
        ],
        Province: [
          { validator: (rule, value, callback) => {
            if (this.form.Province === '' || this.form.City === '' || this.form.Area === '' || this.form.Street === '') {
              callback(new Error('请输入详细地址'))
            }
            callback()
          }, trigger: 'blur' }
        ]
      },
      col: [],
      dropCol: [],
      materialFiles: [],
      form: {
        IDX: 0,
        ApplyDate: '',
        DepartID: null,
        DepartNames: '',
        FillFormUserID: '',
        ApplyPersonID: 2,
        ApplyPersonName: 2,
        MaterialName: '',
        NickName: '',
        InvCode: '',
        MaterialTypeID: 1,
        InvTypeID: 1,
        InvTypeCode: null,
        BrandID: 1,
        SourceAreaID: null,
        SourceAreaName: null,
        Specification: '1',
        MinPacking: 'test001', // 最小包装
        StorageNo: 'test001', // 库存代码
        StockUnitN: 1, // 单位
        ShelfLifeNum: 36, // 产品有效期
        ShelfLifeType: '3', // 有效期状态：0 长期有效，1 年，2 月，3 日（新增）
        isLong: null, // 产品有效期是否长期
        TransportCondition: 1, // 运输条件
        ManuPartNum: 'test001',
        RegisterNo: 'test001', // 注册证号/批准文号/备案凭证号
        RegisterStart: '2020-01-10',
        RegisterEnd: '2021-06-02',
        RegisterCardType: '0', // 注册证是否是长期有效 0: 否 1：是
        RegisterPerson: 'test001', // 医疗器械注册人
        ProductFactory: 'test001',
        MaterialPermissionPerson: 'test001',
        ProductLicenseNo: 'test001',
        StockCode: 'test001',
        StorageConditionID: null,
        DICode: 'test001',
        MaterialRemark: 'test001',
        Tax: 0, // 产品税率
        TaxType: null, // 税率类别
        PlanPrice: 0,
        Price: 0
      },
      // 品牌
      brandlist: [],
      // 请求列表参数
      listParas: {
        Keyword: '', // 搜索关键字
        SearchType: 0, // 搜索状态：0：模糊搜索，1：高级搜索
        ApproveStatus: -1, // 审批状态：0：待发起，1：审核中，2：已通过， :3：已驳回，4：已禁用
        MaterialSearchType: 0, // 产品检索类别，0：全部，1：药品 2：医疗器械
        PageIndex: 1,
        PageSize: 10,
        QueryType: 1, // 1 全部 2 药品 3 医疗器械
        RegisterNo: '',
        DINum: '',
        ApplyDateStart: '',
        ApplyDateEnd: '',
        MaterialName: '',
        NickName: '',
        BrandName: '',
        InvTypeName: null,
        MaterialTypeName: null,
        ProductFactory: '',
        ProductLicenseNo: '',
        InvCode: '',
        Specification: '',
        StockUnitN: '',
        StorageConditionName: null,
        StorageNo: '',
        SourceAreaID: '',
        SourceAreaName: '',
        ManuPartNum: '',
        ApproveState: -1,
        StockCode: null
      },
      tableHeight: 'calc(100vh - 340px)',
      tableBackground: true,
      tableLoading: false,
      tableTotalSize: 0,
      tablePageSize: 10,
      pagerCount: 5,
      tableData: [],
      tableDataloading: false,
      IsMore: 0,
      InvTypeList: [], // 物料分类
      materialTypeList: [{
        IDX: 1,
        InvTypeCode: '2312',
        InvTypeName: '312312'
      }], // 物料类型
      UnitList: [], // 单位
      DepartList: [], // 部门
      ConditionList: [], // 储存 运输条件
      // 选择申请人
      selUsername: '',
      selUserVisible: false,
      UserList: [], // 人员
      UserListloading: false,
      UserListTotal: 0,
      UserCurrentRow: null,
      UserListParas: {
        PageIndex: 1,
        PageSize: 5,
        Name: '',
        permissionGroupInfoId: 0 // 部门id
      },
      // 选择原产地
      selSourcename: '',
      selSourceVisible: false,
      SourceList: [], // 原产地
      SourceListloading: false,
      SourceListTotal: 0,
      SourceCurrentRow: null,
      SourceListParas: {
        PageIndex: 1,
        PageSize: 5,
        keyWords: ''
      },
      AdvancedsearchVisibel: false,
      ApproveStateLisst: [
        {
          id: 0,
          value: '待发起'
        },
        {
          id: 1,
          value: '审核中'
        },
        {
          id: 2,
          value: '已通过'
        },
        {
          id: 3,
          value: '已驳回'
        },
        {
          id: 4,
          value: '已禁用'
        }
      ],
      view_item: {},
      sendMsgForm: {
        RecordID: '',
        MessageType: '',
        FileExtName: '',
        Message: '',
        AtUserID: '',
        Reason: '' },
      RecordInfo: {
        RecordInfo: {}
      },
      RecordList: [],
      RecordEditList: [],
      UserList_appv: [],
      ChatList: [],
      input_value: '',
      types: [],
      activeName_business: 'first',
      process_list: [{
        name: '审批编号',
        value: 'ApprovalCode',
        icon: 'el-icon-bell'
      }, {
        name: '流程状态',
        value: 'StatusString',
        icon: 'el-icon-position'
      }, {
        name: '审批类型',
        value: 'ApprovalTypeString',
        icon: 'el-icon-mobile'
      }, {
        name: '申请部门',
        value: 'ApprovalApplyDepartment',
        icon: 'el-icon-coordinate'
      }, {
        name: '申请人',
        value: 'ApprovalApplyUser',
        icon: 'el-icon-user'
      }, {
        name: '发起时间',
        value: 'BeginTimeString',
        icon: 'el-icon-watch'
      }],
      MaterialFileList: [],
      showViewer: false,
      // 从物料库选择产品
      addMaterialVisbel: false,
      addMaterialListParas: {
        InvCode: '',
        InvName: '',
        Specification: '',
        BrandName: '',
        ManuPartNum: '',
        PageIndex: 1,
        PageSize: 5
      },
      addMaterialList: [],
      addMaterialloading: false,
      addMateriallistSize: 0,
      // StockCodeList
      StockCodeList: []
    }
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  mounted() {
    // 获取按钮权限
    // this.getBottons('ppsy')
    // 获取人员信息
    this.getUserinfo()
    window.addEventListener('keydown', this.serchByenter)
    if (this.$route.params.from === 'Guidepage') {
      this.openAddMaterial()
    }
  },
  methods: {
    addmaterial() {
      this.addMaterialVisbel = false
      this.$router.push({ name: 'productEdit1', query: { idx: -1 }, params: { type: 1 }})
    },
    // 获取仓位接口
    getLocation() {
      const _this = this
      _this.$api.GetStockCodeList({ KeyWords: '', PageIndex: 1, PageSize: -1 }).then((res) => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.StockCodeList = res.Message
        } else {
          console.log('获取仓位列表出错： ' + res.RetMsg)
        }
      })
    },
    selectAll(e) {
      this.selects = e
    },
    select(row) {
      this.selects = row
    },
    // enter查询
    serchByenter(e) {
      console.log(e.keyCode)
      if (e.keyCode === 13) {
        this.listParas.PageIndex = 1
        this.getTableDataAsync(1)
      }
    },
    addMateriallistCurrentChange(val) {
      this.addMaterialListParas.PageIndex = val
      this.getMaterialList()
    },
    // 打开物料库添加物料框
    openAddMaterial() {
      this.addMaterialVisbel = true
      this.addMaterialListParas = {
        InvCode: '',
        InvName: '',
        Specification: '',
        BrandName: '',
        ManuPartNum: '',
        PageIndex: 1,
        PageSize: 5
      }
      this.addMaterialList = []
      // this.getMaterialList()
    },
    // 获取可选择物料列表
    getMaterialList() {
      const _this = this
      _this.addMaterialloading = true
      _this.addMaterialList = []
      // _this.addMateriallistSize = 0
      $post.GetMaterialInfoList(_this.addMaterialListParas).then(res => {
        _this.addMaterialloading = false
        if (res.RetCode === '0') {
          _this.addMaterialList = res.Message
          _this.addMateriallistSize = res.Total
        } else {
          _this.$message.error('获取物料库信息出错:' + res.RetMsg)
          // console.log('获取物料库信息出错')
        }
      })
    },
    // 选中物料（入表
    // conFirmAddoneMaterial(_row) {
    //   // 确认是否添加到物料库
    //   this.$confirm('确认将物料：' + _row.InvCode + ' 发起首营?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.addoneMaterial(_row)
    //   }).catch(() => {
    //   })
    // },
    // 选中物料 去编辑页
    conFirmAddoneMaterial_2(_row) {
      // 处理物料信息(避免多次查询 先初始化 数据) -1024 表示选中物料库数据发起首营
      const _materialInfo = {
        MaterialID: _row.IDX,
        MaterialName: _row.InvName,
        NickName: _row.NickName,
        InvCode: _row.InvCode,
        InvTypeID: _row.InvTypeID,
        MaterialTypeID: _row.MaterialTypeID,
        MaterialSubTypeID: _row.MaterialSubTypeID,
        BrandID: _row.BrandID,
        BrandName: _row.BrandName,
        SourceAreaID: _row.SourceAreaID, // ----
        SourceAreaName: _row.SourceAreaName, // ----
        Specification: _row.Specification,
        StockUnitN: _row.StockUnitN,
        ManuPartNum: _row.ManuPartNum,
        RegisterNo: _row.RegisterNo,
        RegisterStart: _row.RegisterStart.indexOf('1900') > -1 ? '' : _row.RegisterStart,
        RegisterEnd: _row.RegisterEnd.indexOf('1900') > -1 ? '' : _row.RegisterEnd,
        StorageConditionID: _row.StorageConditionID
      }
      this.addMaterialVisbel = false
      this.$router.push({ name: 'productEdit1', params: { idx: -1024, type: 1, materialInfo: _materialInfo }})
    },
    // addoneMaterial(_row) {
    //   const _this = this
    //   // 请求接口
    //   _this.addMaterialloading = true
    //   $post.MakeFirstFromMaterial({ materialID: _row.IDX }).then(res => {
    //     _this.addMaterialloading = false
    //     if (res.RetCode === '0') {
    //       _this.addMaterialVisbel = false
    //       // _this.getTableDataAsync(1)
    //       _this.$message.success('发起首营成功！')
    //       _this.$router.push({ name: 'productEdit1', params: { type: 1, idx: res.Message }})
    //     } else {
    //       _this.$message.error('发起首营出错：' + res.RetMsg)
    //     }
    //   })
    // },
    // zhazns
    showImage(src) {
      this.MaterialFileList = []
      this.showViewer = true
      this.MaterialFileList.push(src)
    },
    // 重新发起
    restart() {
      this.showElement = false
      // this.edit(this.view_item)
      // this.$message.error('重新发起？')
      this.$router.push({ name: 'productEdit1', params: { idx: this.view_item.IDX, type: 1 }})
    },
    // 发送信息
    sendMsg(isFile) {
      if (!isFile) {
        if (!this.input_value) return this.$message.error('内容不能为空')
      }

      // eslint-disable-next-line no-unused-vars
      // let op = document.getElementsByClassName('op')
      if (this.base64) {
        this.sendMsgForm.MessageType = 0
        this.sendMsgForm.Message = this.base64
      } else {
        this.sendMsgForm.MessageType = 1
        this.sendMsgForm.Message = this.input_value
      }
      console.log('msg', this.sendMsgForm)
      this.input_value = ''
      this.$api.SaveApprovalLeaveMessage(this.sendMsgForm).then(res => {
        console.log(res)
        this.sendMsgForm.AtUserID = ''
        this.base64 = null
        if (res.RetCode === '0') {
          this.ChatList.MessageList.push(res.Message)
          this.$nextTick(() => {
            this.content_view_chat.scrollTop = this.content_view_chat.scrollHeight
          })
          return this.$message.success('留言成功')
        }
      })
    },
    // 催办
    cuiban() {
      this.$api.ApprovalUrging({
        IDX: this.view_item.RecordID
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0') {
          return this.$message.success('催办成功')
        }
        return this.$message.error('催办失败')
      })
      // this.$message.info('正在开发中')
    },
    viewShenP(row) {
      console.log(row)
      if (row.Status === 0) return
      this.formJson = JSON.parse(row.FormJson)
      this.dataJson = JSON.parse(row.DataJson)
      this.view_item = row
      console.log(this.formJson, this.dataJson)
      this.showElement = true

      // this.content_view_chat.scrollTop = this.content_view_chat.offsetHeight
      this.sendMsgForm.RecordID = row.RecordID
      this.GetApprovalRecordList(row.RecordID)
      this.GetApprovalLeaveMessageList(row.RecordID)
      this.GetApprovalRecordInfo(row.RecordID)
      this.GetApprovalUserList(row.RecordID)
      this.GetCertificateType()
      this.GetApprovalRecordEditList(row.RecordID)
    },
    GetCertificateType() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },
    // 导出审批日志
    DownApprovalRecordList() {
      this.$api.DownApprovalRecordList({
        IDX: this.view_item.IDX
      }).then(res => {
        if (res.RetCode === '0') {
          this.$minCommon.downloadFile(res.Message)
          return this.$message.success('导出成功')
        }
      })
    },
    selectFile(e) {
      this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
    },
    getFile(e) {
      const inputFile = this.$refs.filElem.files[0]
      console.log('getFilePath', inputFile)
      if (inputFile.type && inputFile.size) {
        var reader = new FileReader()
        //   const url = ''
        reader.readAsDataURL(inputFile)
        this.sendMsgForm.FileExtName = '.' + inputFile.type.split('/')[1]
        // this.MessageType = 0
        reader.onload = (e) => {
          // this.sendMsgForm.Message  =
          this.base64 = ''
          this.base64 = e.target.result
          console.log(e.target.result) // base64
          // this.isPhoto = `☮${inputFile.name}☮`
          // this.input_value += this.isPhoto
          this.sendMsg(true)
        }
      }
    },
    // 监听选项
    tabClick(a) {
      if (a.name === 'third') {
        this.$nextTick(() => {
          this.content_view_chat = document.getElementById('content_view')
          this.content_view_chat.scrollTop = this.content_view_chat.scrollHeight
        })
      }
    },
    // 下载证件
    downCampZip() {
      this.$api.DownloadFirstCampZip({
        JsonStr: this.view_item.FileJsonStr
      }).then(res => {
        if (res.RetCode === '0' && res.Message) {
          return this.$minCommon.downloadFile(res.Message, '.zip')
        }
        return this.$message.error('请求失败:' + res.RetMsg)
      }).catch((err) => {
        return this.$message.error('请求失败:', err)
      })
    },
    // 审批流程
    GetApprovalRecordInfo(RecordID) {
      this.$api.GetApprovalRecordInfo({
        RecordID
      }).then(res => {
        console.log('dasdaadsd', res)
        this.RecordInfo = res.Message
      }).catch(err => console.log(err))
    },
    // 留言记录
    GetApprovalLeaveMessageList(RecordID) {
      this.$api.GetApprovalLeaveMessageList({
        RecordID
      }).then(res => {
        console.log(res)
        this.ChatList = res.Message
      })
    },
    // 获取审批日志
    GetApprovalRecordList(ApprovalRecordID) {
      this.$api.GetApprovalRecordList({
        ApprovalRecordID
      }).then(res => {
        console.log(res)
        this.RecordList = res.Message
      })
    },
    // 查询修改日志
    GetApprovalRecordEditList(RecordID) {
      this.$api.GetApprovalRecordEditList({
        RecordID
      }).then(res => {
        console.log(res)
        this.RecordEditList = res.Message
      })
    },
    // @人列表
    GetApprovalUserList(RecordID) {
      this.$api.GetApprovalUserList({
        RecordID
      }).then(res => {
        console.log(res)
        this.UserList_appv = res.Message
      })
    },
    // 校验删除
    confirmDelete(_row) {
      const _this = this
      _this.$confirm('确认删除当前数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DeleteData(_row)
      }).catch(() => {
      })
    },
    DeleteData(_row) {
      const _this = this
      $post.DeleFirstCampMateriaInfo({ FCMIdx: _row.IDX }).then((res) => {
        if (res.RetCode === '0') {
          _this.$message('删除成功！')
          _this.getTableDataAsync(1)
        } else {
          _this.$message('删除失败：' + res.RetMsg)
        }
      })
    },
    Turn(url) {
      this.$router.push(url)
    },
    closeadvance() {
      this.listParas.SearchType = 0
    },
    // 初始化编辑页
    InitEdit() {
      this.form.DepartID = this.UserInfo.PermissionGroupID // ID-PermissionGroupID
      this.form.ApplyPersonID = this.UserInfo.UserID
      this.form.ApplyPersonName = this.UserInfo.NickName
      this.form.FillFormUserID = this.UserInfo.UserID
      this.getNowTime() // 当前日期
      this.getInvType() // 物料分类
      this.getDepartList() // 部门列表
      this.getConditionList() // 存储/运输条件
    },
    initadvance() {
      this.getLocation()
      this.getConditionList() // 存储/运输条件
      this.getInvType() // 物料分类
      this.clearAdvan()
    },
    clearAdvan() {
      this.listParas.SearchType = 0
      this.listParas.RegisterNo = null
      this.listParas.DINum = null
      this.listParas.ApplyDateStart = null
      this.listParas.ApplyDateEnd = null
      this.listParas.MaterialName = null
      this.listParas.NickName = null
      this.listParas.BrandName = null
      this.listParas.InvTypeName = null
      this.listParas.MaterialTypeName = null
      this.listParas.ProductFactory = null
      this.listParas.ProductLicenseNo = null
      this.listParas.InvCode = null
      this.listParas.Specification = null
      this.listParas.StockUnitN = null
      this.listParas.StorageConditionName = null
      this.listParas.StorageNo = null
      this.listParas.ManuPartNum = null
      this.listParas.StorageConditionID = null
      this.listParas.SourceAreaID = null
      this.listParas.SourceAreaName = null
      this.listParas.ApproveState = -1
      this.listParas.StockCode = null
    },
    confirmserchadvance() {
      this.listParas.PageIndex = 1
      this.getTableDataAsync(1)
      this.AdvancedsearchVisibel = false
    },
    OpenAdvanSearch() {
      this.initadvance()
      this.listParas.SearchType = 1
      this.AdvancedsearchVisibel = true
    },
    // 选择原产地
    SourceListhandleCurrentChange(val) {
      this.SourceListParas.PageIndex = val
      this.getTableDataAsync(2)
    },
    checkSource() {
      this.listParas.SourceAreaID = this.SourceCurrentRow.IDX
      this.listParas.SourceAreaName = this.SourceCurrentRow.SourceAreaName
      this.selSourceVisible = false
    },
    selSource() {
      this.SourceCurrentRow = null
      this.selSourceVisible = true
      this.SourceList = []
      this.SourceListParas.keyWords = ''
      this.getSourceList()
    },
    SourceChage(val) {
      console.log(val)
      this.$refs.SourceTable.setCurrentRow(val)
      this.SourceCurrentRow = val
    },
    // 获取原产地
    getSourceList() {
      const _this = this
      _this.getTableDataAsync(2)
    },
    // 获取人员信息
    getUserinfo() {
      const _this = this
      $post.getUserInfo().then((res) => {
        if (res.RetCode === '0') {
          _this.UserInfo = res.Message
        }
      })
    },
    listhandleCurrentChange(val) {
      this.listParas.PageIndex = val
      this.getTableDataAsync(1)
    },
    // 选择申请人
    UserListhandleCurrentChange(val) {
      this.UserListParas.PageIndex = val
      console.log(val)
      this.getTableDataAsync(3)
    },
    checkUser() {
      this.form.ApplyPersonID = this.UserCurrentRow.UserID
      this.form.ApplyPersonName = this.UserCurrentRow.NickName
      this.selUserVisible = false
    },
    selUser() {
      this.UserCurrentRow = null
      this.selUserVisible = true
      this.UserList = []
      this.getUserList()
    },
    UserChage(val) {
      console.log(val)
      this.$refs.UserTable.setCurrentRow(val)
      this.UserCurrentRow = val
    },
    // 获取人员
    getUserList() {
      const _this = this
      // _this.UserListParas.permissionGroupInfoId = _this.form.DepartID
      _this.UserListParas.permissionGroupInfoId = -1
      _this.getTableDataAsync(3)
    },
    // 获取储存 运输条件
    getConditionList() {
      const _this = this
      $post.getConditionList().then((res) => {
        _this.ConditionList = res.Message
      })
    },
    // 获取部门
    getDepartList() {
      const _this = this
      $post.getDepartList().then((res) => {
        _this.DepartList = res.Message
      })
    },
    // 获取物料类型
    getMaterialType() {
      const _this = this
      $post.getMaterialType().then((res) => {
        _this.InvTypeList = res.Message
      })
    },
    // 获取物料分类
    getInvType() {
      const _this = this
      $post.getInvTypeList().then((res) => {
        _this.materialTypeList = res.Message
      })
    },
    Golist() {
      this.getTableDataAsync(1)
      this.active = 0
    },
    IsMoreFun(_isMore) {
      this.IsMore = _isMore
    },
    getNowTime() {
      var now = new Date()
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() // 得到月份
      var date = now.getDate() // 得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.form.ApplyDate = defaultDate
      // this.$set(this.form.ApplyDate, 'date', defaultDate)
    },
    // 行拖拽
    rowDrop() {
      const tbody = document.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData1.splice(oldIndex, 1)[0]
          _this.tableData1.splice(newIndex, 0, currRow)
        }
      })
    },
    // 列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector('.el-table__header-wrapper tr')
      this.sortable = Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        onEnd: evt => {
          console.log('evt', evt)
          const oldItem = this.dropCol[evt.oldIndex]
          this.dropCol.splice(evt.oldIndex, 1)
          this.dropCol.splice(evt.newIndex, 0, oldItem)
        }
      })
    },
    shibie() {
      axios({
        // general_basic
        url: '/v1/business_license?access_token=24.4fb298da009679691eccaf045e1746f9.2592000.1614228161.282335-23590046',
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: {
          image: this.imagUrl
        },
        transformRequest: [function(data) {
          let ret = ''
          for (const it in data) {
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          }
          return ret
        }]
      }).then(res => {
        console.log(res)
        this.form.a = res.data.words_result['社会信用代码'].words
        this.form.s = res.data.words_result['组成形式'].words
        this.form.d = res.data.words_result['经营范围'].words
        this.form.f = res.data.words_result['成立日期'].words
        this.form.g = res.data.words_result['法人'].words
        this.form.h = res.data.words_result['注册资本'].words
        this.form.j = res.data.words_result['证件编号'].words
        this.form.k = res.data.words_result['地址'].words
        this.form.l = res.data.words_result['有效期'].words
        this.form.z = res.data.words_result['单位名称'].words
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async getTableDataAsync(type = 1) {
      const _this = this
      let resp = null
      switch (type) {
        case 1: {
          _this.tableDataloading = true
          resp = await $post.getFistProductList(_this.listParas)
          _this.tableDataloading = false
          const { Message, Total } = resp
          _this.tableData = Message
          _this.tableTotalSize = Total
          _this.clearAdvan()
          break
        }
        case 2: {
          _this.SourceListloading = true
          resp = await $post.getSorceList(_this.SourceListParas)
          _this.SourceListloading = false
          const { Message, Total } = resp
          _this.SourceList = Message
          _this.SourceListTotal = Total
          break
        }
        case 3: {
          _this.UserListloading = true
          resp = await $post.getUserList(_this.UserListParas)
          _this.UserListloading = false
          const { Message, Total } = resp
          _this.UserList = Message
          _this.UserListTotal = Total
          break
        }
        default: {
          console.log(type + ' 未找到匹配数据源')
          return
        }
      }
      return resp
    },
    filterTag(value, row) {
      return row.ApproveState === value
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getInfo(index) {
      switch (index) {
        case 2:
          return { info: '已通过', type: 'success' }
        case 0:
          return { info: '编辑中', type: 'info' }
        case 1:
          return { info: '审核中', type: 'primary' }
        case 3:
          return { info: '已驳回', type: 'danger' }
        default:
          return { info: '暂无', type: 'warning' }
      }
    },
    toStep(n) {
      console.log(n)
      if (n === 1) {
        this.InitEdit()
      }
      if (n === 0) {
        this.getTableDataAsync(1)
      }
      this.active = n
    },
    saveComfirm() {
      this.$confirm('确认提交信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.save()
      }).catch(() => {
      })
    },
    save() {
      const _this = this
      console.log(_this.form)
      let isGoing = true
      _this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 信息校验成功 调用接口
          isGoing = true
        } else {
          _this.$message('请完整填写信息')
          isGoing = false
        }
        if (!isGoing) {
          return false
        }
        $post.EditProductInfo(_this.form).then((res) => {
          if (res.RetCode === '0') {
            // 弹出选择框
            _this.$confirm('保存成功', '提示', {
              confirmButtonText: '上传资质',
              cancelButtonText: '返回列表',
              type: 'success',
              center: true
            }).then(() => {
              _this.getProductDetaisInfo(res.Message)
              _this.active = 2
            }).catch(() => {
              _this.getTableDataAsync(1)
              _this.active = 0
            })
          }
        })
      })
      // this.active = 2
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      $post.getProductInfo({ FCMIdx: _idx }).then(res => {
        if (res.RetCode === '0') {
          _this.materialdetaisinfo = res.Message
        }
      })
    },
    // 发起产品首营
    start() {
      this.$router.push('/product/productEdit')
      // this.InitEdit()
      // this.active = 1
    },
    cancle() {
      this.active = 0
    },
    // 导出
    exportData() {
      const _this = this
      _this.tableDataloading = true
      this.listParas.FieldStr = JSON.stringify(this.tableListJson[ this.$refs.FieldManagement.name ])
      this.listParas.SelectIDList = this.selectsArr
      this.$refs.ExportLoading.open()
      $post.exportProduct(_this.listParas).then((res) => {
        _this.tableDataloading = false
        if (res.RetCode === '0') {
          // _this.downloadFile(res.Message, _this.guid() + '.xlsx')
          const name = this.$minCommon.getExportName('产品首营')
          this.$refs.ExportLoading.success(res.Message, name)
          return this.$minCommon.downloadFile(res.Message, name)
        }
        return _this.$message.error('导出数据出错：' + res.RetMsg)
      })
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    guid() {
      return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
    },
    // 处理字节流
    downloadFile(data, fileName) {
      var byteString = atob(data)
      var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer) // 创建视图
      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([intArray], { type: '' })
      // 获取heads中的filename文件名
      const downloadElement = document.createElement('a')
      // 创建下载的链接
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      // 下载后文件名
      downloadElement.download = fileName
      document.body.appendChild(downloadElement)
      // 点击下载
      downloadElement.click()
      // 下载完成移除元素
      document.body.removeChild(downloadElement)
      // 释放掉blob对象
      window.URL.revokeObjectURL(href)
    },
    // 下载zip
    downloadZip(_JsonStr) {
      const _this = this
      const paras = {
        JsonStr: _JsonStr
      }
      $post.DownloadFirstCampZip(paras).then((res) => {
        if (res.RetCode === '0') {
          _this.downloadFile(res.Message, _this.guid() + '.zip')
        } else {
          _this.$message(res.RetMsg)
        }
      })
    },
    // 禁用 启用产品
    EnableProduct(_idx, type) {
      const _this = this
      $post.EnableProduct({ FCMIDX: _idx, ApproveState: type }).then((res) => {
        if (res.RetCode === '0') {
          _this.$message({
            message: '操作成功',
            type: 'success'
          })
          _this.getTableDataAsync()
        } else {
          _this.$message({
            message: res.Message,
            type: 'success'
          })
        }
      })
    },
    getShefType(_type) {
      switch (_type) {
        case 1:
          return { value: 1, text: '年' }
        case 2:
          return { value: 2, text: '月' }
        case 3:
          return { value: 3, text: '日' }
        default: return { value: 0, text: '' }
      }
    },
    getTaxInfo(_tax) {
      switch (_tax) {
        case 0.17:
          return '17'
        case 0.16:
          return '16'
        case 0.13:
          return '13'
        case 0.11:
          return '11'
        case 0.1:
          return '10'
        case 0.06:
          return '6'
        case 0.03:
          return '3'
        case 0.01:
          return '1'
        case 1:
          return '0'
        default: return null
      }
    }
  }
}
export default productList
