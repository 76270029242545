<template>
  <div class="step flex j-center jindutiao m-top-10">
    <el-steps :active="step" simple style="width:100%">
      <el-step
        v-for="(item,index) in stepsData"
        :key="index"
        :title="item.name"
        :icon="item.icon"
      />
    </el-steps>
  </div>
</template>
<script>
export default {
  name: 'Steps',
  props: {
    step: {
      type: Number,
      default: 0
    },
    stepsData: {
      type: Array,
      default: () => [
        {
          id: '1',
          name: '新增首营企业',
          icon: 'el-icon-document-add'
        },
        {
          id: '2',
          name: '完善企业信息',
          icon: 'el-icon-edit'
        },
        // {
        //   id: '3',
        //   name: '上传企业证照',
        //   icon: 'el-icon-upload'
        // },
        {
          id: '4',
          name: '等待审核',
          icon: 'el-icon-time'
        }
      ]
    }
  },
  data() {
    return {
      steps: [
        {
          id: '1',
          name: '新增首营企业'
        },
        {
          id: '2',
          name: '完善企业信息'
        },
        {
          id: '3',
          name: '上传企业证照'
        },
        {
          id: '4',
          name: '等待审核'
        }
      ]
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.jindutiao .el-steps--simple{ padding: 0;}

.el-steps--simple {
  background-color: white;
}

.steppointcolor1{
    color: #4395ff;
}
.steppointcolor2{
    color: #bfc3cc;
}
.dereictstrong{
    font-size: 45px;
}
.dereict{
    float:right;
    line-height:27px;
}
.points{
    font-size:50px;
}
.steppoint{
    line-height:5px;
    margin-left:5px;
}
.item{
    float: left;
    width: 75px;
    height: 60px;
}
.item1{
    float: left;
    width: 106px;
    height: 60px;
}
.steps{
        list-style: none;
        float: left;
        margin-left: 5px;
    }
.words{
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
    }
  .stepsli{
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin:0 auto;
        color: white;
        text-align:center;
        line-height:33px;
    }
     .stepslicolor1{
         background: #4395ff;
    }
    .stepslicolor2{
        background: #bfc3cc;
    }

</style>
