<template>
  <div class="merchants">
    <PageHeader dilog :border="false" title="产品首营流程" center>
      <Steps :step="active" :steps-data="stepsData" />
    </PageHeader>
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
    <div class=" p-lr-20">
      <div v-if="active === 0">
        <div class="searchView  flex a-center m-tb-10 j-between">
          <div class="flex">
            <div v-if="$minCommon.checkMeau('ppsyexport', userBottons)" class="out white  bg p-lr-10 flex a-center m-right-10 ModelBttton-white-30" @click="exportData">
              <i class="el-icon-upload2 f14  p-right-10 " />   导出
            </div>
            <div v-if="$minCommon.checkMeau('ppsyfieldmanage', userBottons)" class="out p-lr-10 flex a-center m-right-10 ModelBttton-white-30" @click="() => $refs.FieldManagement.openSetFieldManagement()">
              <i class="el-icon-setting f14 p-right-5" />   字段管理
            </div>
            <!-- <div class="out p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10">
              <i class="el-icon-delete f15 p-right-5" />   删除选中
            </div> -->
          </div>
          <div v-if="$minCommon.checkMeau('ppsyview', userBottons)" class="flex SearchBox_30" style="flex:1">
            <el-input v-model="listParas.Keyword" placeholder="可通过物料名称、物料号、DI码、物料简称、品牌、创建人、备注、规格搜索" class="rule-input-edit">
              <template slot="prepend">
                <div class="pointer" @click="OpenAdvanSearch">
                  <i class="el-icon-s-operation" />高级搜索</div></template>
              <div slot="append" @click="getTableDataAsync(1)">搜  索</div>
            </el-input>
            <div v-if="$minCommon.checkMeau('ppsyoriginate', userBottons)" class="out p-left-10  flex a-center">
              <el-button class="ModelBttton-white-30" round type="primary" style="background:rgb(58, 120, 241)" @click="openAddMaterial">发起新产品首营</el-button>
            </div>
          </div>
        </div>
        <el-table
          ref="multipleTable"
          v-loading="tableDataloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="tableData"
          tooltip-effect="dark"
          show-header
          height="calc(100vh - 325px)"
          row-key="id"
          border
          @select-all="selectAll"
          @select="select"
          @selection-change="handleSelectionChange"
        >
          <af-table-column
            fixed="left"
            type="selection"
            width="40"
          />
          <af-table-column
            label="当前状态"
            width="90"
            prop="Status"
            filter-placement="bottom-end"
            :filter-method="filterTag"
            :filters="[{text: '已通过', value: 2}, {text: '编辑中', value: 0}, {text: '审核中', value: 1}, {text: '已驳回', value: 3}]"
          >
            <template slot-scope="scope">
              <el-link v-if="$minCommon.checkMeau('ppsyapprovebox', userBottons)" size="mini" :type="getInfo(scope.row.Status).type" @click="viewShenP(scope.row)">{{ getInfo(scope.row.Status).info }}</el-link>
              <span v-if="!$minCommon.checkMeau('ppsyapprovebox', userBottons)">{{ getInfo(scope.row.Status).info }}</span>
            </template>
          </af-table-column>
          <template v-for="(item,index) in tableListJson.productlist">
            <af-table-column
              v-if="item.isChecked && !item.productTax && item.prop !== 'ApplyDate' && !item.RegisterDate && !item.product && !item.typeAdd"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            />
            <af-table-column
              v-if="item.isChecked && item.prop === 'ApplyDate'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 申请日期 -->
              <template slot-scope="scope">
                {{ $minCommon.setDateFormate(scope.row.ApplyDate) }}
              </template>
            </af-table-column>

            <af-table-column
              v-if="item.isChecked && item.typeAdd"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 物料类型 -->
              <template slot-scope="scope">
                <span>{{ scope.row.MaterialTypeName }}</span>/<span>{{ scope.row.MaterialSubTypeName }}</span>
              </template>
            </af-table-column>
            <af-table-column
              v-if="item.isChecked && item.product "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 产品有效期 -->
              <template v-if="item.isChecked && item.product" slot-scope="scope">
                <span v-if="scope.row.ShelfLifeType === 0">长期</span>
                <span v-if="scope.row.ShelfLifeType > 0">{{ scope.row.ShelfLifeNum }} {{ getShefType(scope.row.ShelfLifeType).text }}</span>
              </template>
            </af-table-column>
            <af-table-column
              v-if="item.isChecked && item.RegisterDate "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 证书截止日期 -->
              <template slot-scope="scope">
                <span v-if="scope.row.ShelfLifeType === 0">长期</span>
                <span v-if="scope.row.ShelfLifeType > 0">{{ scope.row.ShelfLifeNum }} {{ getShefType(scope.row.ShelfLifeType).text }}</span>
              </template>
            </af-table-column>
            <af-table-column
              v-if="item.isChecked && item.productTax "
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 产品税率(%) -->
              <template slot-scope="scope">
                {{ getTaxInfo(scope.row.Tax) }}
              </template>
            </af-table-column>

          </template>
          <af-table-column
            fixed="right"
            prop="address"
            align="center"
            label="操作"
            width="50"
          >
            <template slot-scope="scope">
              <el-popover
                placement="left"
                trigger="hover"
                :width="50"
                popper-class="test_pop_view"
              >
                <div class="DropDownList">
                  <div v-if="$minCommon.checkMeau('ppsyview', userBottons)" class="OperationButton">
                    <el-link :underline="false" @click="() => { $router.push({ name: 'ProductApprove1', params: { type: 1, idx: scope.row.IDX }}) }"><i class="el-icon-view p-right-10" />查看</el-link>
                  </div>
                  <div v-if="(scope.row.Status === 0 || scope.row.Status === 3) && $minCommon.checkMeau('ppsyedit', userBottons)" class="OperationButton">
                    <el-link :underline="false" @click="() => { $router.push({ name: 'productEdit1', params: { type: 1, idx: scope.row.IDX }}) }"><i class="el-icon-edit p-right-10" />编辑</el-link>
                  </div>
                  <div v-if="$minCommon.checkMeau('ppsydownload', userBottons)" class="OperationButton">
                    <el-link :underline="false" @click="downloadZip(scope.row.FileJsonStr)"><i class="el-icon-download p-right-10" />下载</el-link>
                  </div>
                  <div v-if="(scope.row.Status === 0) && $minCommon.checkMeau('ppsyedit', userBottons)" class="OperationButton">
                    <el-link :underline="false" @click="confirmDelete(scope.row)"><i class="el-icon-download p-right-10" />删除</el-link>
                  </div>
                </div>
                <i slot="reference" class="el-icon-more" />
              </el-popover>
            </template>
          </af-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :total="tableTotalSize"
            :current-page="listParas.PageIndex"
            :page-size="listParas.PageSize"
            :background="tableBackground"
            layout="prev, pager, next, jumper"
            @current-change="listhandleCurrentChange"
          />
        </div>
      </div>
      <!-- 选择申请人 -->
      <el-dialog title="选择申请人" :visible.sync="selUserVisible">
        <el-form :inline="true">
          <el-form-item label="申请人姓名" style="width:300px">
            <el-input v-model="selUsername" />
          </el-form-item>
          <div style="float:right">
            <el-button @click="checkUser">选择数据</el-button>
            <el-button type="primary" @click="getUserList">查询</el-button>
          </div>
        </el-form>
        <el-table
          ref="UserTable"
          v-loading="UserListloading"
          :data="UserList"
          highlight-current-row
          @current-change="UserChage"
        >
          <af-table-column property="UserID" label="用户ID" />
          <af-table-column property="NickName" label="用户昵称" />
        </el-table>
        <div class="pagination-container">
          <el-pagination
            :total="UserListTotal"
            :current-page="UserListParas.PageIndex"
            :page-size="UserListParas.PageSize"
            layout="prev, pager, next, jumper"
            @current-change="UserListhandleCurrentChange"
          /></div>
      </el-dialog>
      <!-- 选择原产地 -->
      <el-dialog class="DepartmentBox" width="600px" title="选择原产地" :visible.sync="selSourceVisible">
        <el-form :inline="true">
          <el-form-item label="原产地名称">
            <el-input v-model="SourceListParas.keyWords" size="mini" />
          </el-form-item>
          <div style="float:right">
            <el-button size="mini" @click="checkSource">选择数据</el-button>
            <el-button size="mini" type="primary" @click="getSourceList">查询</el-button>
          </div>
        </el-form>
        <el-table
          ref="SourceTable"
          v-loading="SourceListloading"
          :data="SourceList"
          highlight-current-row
          @current-change="SourceChage"
        >
          <af-table-column property="IDX" label="原产地id" />
          <af-table-column property="SourceAreaName" label="原产地名称" />
        </el-table>
        <div class="pagination-container caozuo p-bottom-20">
          <el-pagination
            :total="SourceListTotal"
            :current-page="SourceListParas.PageIndex"
            :page-size="SourceListParas.PageSize"
            layout="prev, pager, next, jumper"
            @current-change="SourceListhandleCurrentChange"
          /></div>
      </el-dialog>
      <!-- 产品高级搜索 -->
      <el-dialog class="InfoBoxTwo5" title="产品高级搜索" :visible.sync="AdvancedsearchVisibel" width="900px" :close-on-click-modal="false" @closed="closeadvance">
        <el-button style="float:right" size="mini" plain @click="clearAdvan">清空搜索条件</el-button>
        <el-form label-position="right" label-width="120px" :inline="true" :model="listParas" size="mini" class="advanser">
          <el-form-item label="注册证号/批准文号/备案凭证号">
            <el-input
              v-model="listParas.RegisterNo"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="DI码">
            <el-input
              v-model="listParas.DINum"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="listParas.ApplyDateStart"
              type="date"
              style="width:calc((100% - 15px) / 2)"
              value-format="yyyy-MM-dd"
            />
            -
            <el-date-picker
              v-model="listParas.ApplyDateEnd"
              type="date"
              style="width:calc((100% - 15px) / 2)"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="物料名称">
            <el-input
              v-model="listParas.MaterialName"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="物料简称">
            <el-input
              v-model="listParas.NickName"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="品牌">
            <el-input
              v-model="listParas.BrandName"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="物料分类">
            <el-select v-model="listParas.InvTypeName" placeholder="" filterable>
              <el-option v-for="matertypeitem in materialTypeList" :key="matertypeitem.IDX" :label="matertypeitem.InvTypeName" :value="matertypeitem.IDX" />
            </el-select>
          </el-form-item>
          <el-form-item label="物料类型">
            <el-input
              v-model="listParas.MaterialTypeName"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="生产厂家">
            <el-input
              v-model="listParas.ProductFactory"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="生产企业许可证号">
            <el-input
              v-model="listParas.ProductLicenseNo"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="物料编号">
            <el-input
              v-model="listParas.InvCode"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <!-- <el-form-item label="规格/型号">
            <el-input
              v-model="listParas.Specification"
              size="mini"
              placeholder=""
            />
          </el-form-item> -->
          <el-form-item label="单位">
            <el-input
              v-model="listParas.StockUnitN"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="储存条件">
            <el-select v-model="listParas.StorageConditionName" placeholder="" filterable>
              <el-option v-for="item in ConditionList" :key="item.IDX" :label="item.StorageConditionName" :value="item.IDX" />
            </el-select>
          </el-form-item>
          <el-form-item label="存货代码">
            <el-input
              v-model="listParas.StorageNo"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="原厂编号">
            <el-input
              v-model="listParas.ManuPartNum"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="原产地">
            <el-input v-model="listParas.SourceAreaName" placeholder="请选择原产地" class="input-with-select">
              <el-button slot="append" icon="el-icon-search" @click="selSource(2)" />
            </el-input>
          </el-form-item>
          <el-form-item label="当前状态">
            <el-select v-model="listParas.ApproveState">
              <el-option v-for="item in ApproveStateLisst" :key="item.id" :label="item.value" :value="item.id" />
              <el-option label=" " :value="-1" :disabled="true" />
            </el-select>
          </el-form-item>
          <el-form-item label="物料所在仓库">
            <!-- <el-input
              v-model="listParas.StockCode"
              size="mini"
              placeholder=""
            /> -->
            <el-select v-model="listParas.StockCode">
              <el-option v-for="(item,index) in StockCodeList" :key="index" :label="item.StockCode" :value="item.StockCode" />
            </el-select>
          </el-form-item>
        </el-form>
        <div class="caozuo p-tb-20 t_line_s m-top-10 t-right">
          <el-button @click="AdvancedsearchVisibel = false">取 消</el-button>
          <el-button type="primary" @click="confirmserchadvance">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 审批弹窗 start-->
    <el-dialog top="5vh" width="80%" custom-class="custom-class1" class="dialogClass" :show-close="false" :visible="showElement">
      <PageHeader title="产品首营申请单"><i class="el-icon-close cursor m-right-10" @click="() => (showElement = false)" /></PageHeader>
      <div class="main_business flex j-between">
        <!-- 左侧 -->
        <div class="main_business_left flex column">
          <div class="top_process flex a-center min-border-bottom">
            <div
              v-for="(i, n) in process_list"
              :key="n"
              class="top_process_item flex j-left a-left"
              :class="i.value !== '6' ? 'min-border-right' : ''"
            >
              <div class="point">
                <i :class="`${i.icon} f25`" />
              </div>
              <div class="flex column pailie">
                <div class="weight f14">{{ i.name }}</div>
                <div class="f12 hui">{{ RecordInfo.RecordInfo[i.value] }}</div>
              </div>
            </div>
          </div>
          <!-- 驳回原因 -->
          <div v-if="RecordInfo.RecordInfo.Status === 2" class="no_reson p-tb-20 p-lr-30 theme-color">
            驳回原因: {{ RecordInfo.RecordInfo.Reason }}
          </div>
          <div class="mid_process">
            <div
              class="flex column m-top-10"
              style="flex: 1; height: auto"
            >
              <div
                v-for="(test, index) in formJson"
                :key="index"
                class="right1 p-lr-20"
              >
                <div class="InfoTitleBox m-top-10 m-bottom-20"><i class="el-icon-caret-right m-right-5" />{{ test.Name === '企业证照' ? '产品证照' : test.Name }}</div>
                <el-form
                  :hide-required-asterisk="false"
                  size="small"
                  :label-width="test.Name === '企业证照' ? '0' : '130px'"
                >
                  <div class="j-between" style="display: flex; flex-wrap: wrap">
                    <template v-for="(item, ia) in test.Value">
                      <el-form-item
                        v-if="item.IsShow && item.Label !== '默认占位' "
                        :key="ia"
                        :required="item.Disabled"
                        :style="{
                          width:
                            test.Name === '企业证照'
                              ? '100%'
                              : item.Colspan === 1
                                ? '30%'
                                : item.Colspan === 2
                                  ? '60%'
                                  : '100%',
                          height: 'auto',
                        }"
                        :label="
                          item.IsShow ? item.Label === '默认占位' ? '' : item.Label : ''
                        "
                      >
                        <template v-if="test.Name === '企业证照'">
                          <div
                            class="flex"
                            style="width: 100%; flex-wrap: wrap"
                          >
                            <div
                              v-for="(info, index_) in dataJson.CertList"
                              :key="info.index_"
                              class="flex j-center m-bottom-20"
                            >
                              <el-image
                                :src="`${b2bstaticUrl}${info.PhotoUrl}`"
                                style="width: 200px; height: auto"
                                @click="
                                  showImage(
                                    `${b2bstaticUrl}${info.PhotoUrl}`
                                  )
                                "
                              />
                              <div class="m-lr-20">
                                <div v-if="info.CertificateName" class="flex  p-tb-5">
                                  <span style="width: 100px">证件类型:</span>
                                  <el-select
                                    v-model="info.CertificateName"
                                    :disabled="disabled"
                                    style="width: 100%"
                                    size="small"
                                    placeholder="请选择"
                                    @change="isChange($event, index_)"
                                  >
                                    <el-option
                                      v-for="item1 in types"
                                      :key="item1.IDX"
                                      :label="item1.Name"
                                      :value="item1.Name"
                                    />
                                  </el-select>
                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                                <div v-if="info.CertificateCode" class="flex p-tb-5">
                                  <span style="width: 100px">证件编号:</span>
                                  <el-input
                                    v-model="info.CertificateCode"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.CertificateCompanyName" class="flex p-tb-5">
                                  <span style="width: 100px">机构名称:</span>
                                  <el-input
                                    v-model="info.CertificateCompanyName"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.beginDate" class="flex  p-tb-5">
                                  <span style="width: 100px">起始日期:</span>
                                  <el-input
                                    v-model="info.beginDate"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                </div>
                                <div v-if="info.ValidToString" class="flex  p-tb-5">
                                  <span style="width: 100px">有效期至:</span>
                                  <el-input
                                    v-model="info.ValidToString"
                                    size="mini"
                                    :disabled="disabled"
                                  />

                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>
                                <div class="flex  p-tb-5">
                                  <span style="width: 100px">备注:</span>
                                  <el-input
                                    v-model="info.Remark"
                                    size="mini"
                                    :disabled="disabled"
                                  />
                                  <!-- <el-input v-model="" size="mini" :disabled="disabled" /> -->
                                </div>

                              </div>
                            </div>
                          </div></template>
                        <el-input
                          v-if="
                            test.Name !== '企业证照' &&
                              item.Label !== '默认占位'
                              && item.Label !== '详细地址'
                              && item.Label !== '产品税率'
                              && item.Label !== '运输条件'
                          "
                          v-model="dataJson[item.Key]"
                          :disabled="disabled"
                          autocomplete="off"
                        />
                        <el-input v-if="item.Label == '运输条件' " :value="dataJson['TransportConditionName']" :disabled="true" autocomplete="off" />
                        <el-input v-if="item.Label == '产品税率' " :value="`${dataJson[item.Key] === null ? ` ` : dataJson[item.Key] * 100 + `%`}`" :disabled="true" autocomplete="off" />
                        <el-input v-if="item.Label == '详细地址' " :value="`${dataJson['Province']}${dataJson['City']}${dataJson['Area']}${dataJson[item.Key]}`" :disabled="true" autocomplete="off" />
                      </el-form-item>
                    </template>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="caozuo p-tb-20 t-right p-right-20 t_line">
            <el-button
              size="medium"
              icon="el-icon-download"
              @click="downCampZip"
            >打包下载证件</el-button>
            <el-button
              v-if="RecordInfo.RecordInfo.Status === 2"
              size="medium"
              type="primary"
              icon="el-icon-refresh"
              @click="restart"
            >重新发起</el-button>
            <el-button
              v-if="RecordInfo.RecordInfo.Status === 0"
              size="medium"
              type="primary"
              icon="el-icon-edit"
              @click="cuiban"
            >催办</el-button>

            <!-- <el-button v-if="view_item.AllowEdit && disabled && view_item.ApprovaPersonStatus === 0" size="mini" type="success" icon="el-icon-edit" @click="setEdit">修改</el-button> -->
            <!-- <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" size="mini" type="danger" icon="el-icon-s-release" @click="RejectApproval(view_item.ApprovalRecordUserDetailsID)">驳回</el-button> -->
            <!-- <el-button v-if="view_item.ApprovaPersonStatus === 0 && view_item.NodeType === 1" size="mini" type="primary" icon="el-icon-s-check" @click="AgreeApprovals(view_item.ApprovalRecordUserDetailsID)">通过审核</el-button> -->
            <!-- <el-button v-if="!disabled" size="mini" type="primary" icon="el-icon-folder-checked" @click="saveForm">保存</el-button>
            <el-button v-if="!disabled" size="mini" type="info" icon="el-icon-bottom-left" @click="goBack">返回</el-button> -->
            <div class="p-right-5" />
          </div>
        </div>
        <!-- 右侧 -->
        <div class="main_business_right min-border-left">
          <div class="top_process min-border-bottom">
            <el-tabs v-model="activeName_business" @tab-click="tabClick">
              <el-tab-pane label="审批日志" name="first">
                <div class="SpeedProgress">
                  <el-timeline style="padding: 5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordList"
                      :key="index"
                      :timestamp="activity.UpdateTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between p-lr-10">
                          <div
                            class="flex j-between p-bottom-5"
                          >
                            <div style="color: #333;font-weight: bolder">
                              {{ activity.NodeTypeString }}
                            </div>
                            <!-- el-icon-s-order -->
                            <i
                              :class="
                                activity.NodeType === 0
                                  ? 'el-icon-tickets'
                                  : activity.NodeType === 1
                                    ? 'el-icon-s-check'
                                    : 'el-icon-position'
                              "
                              style="color: #999"
                            />
                          </div>
                          <div class="bg_hui">
                            <div v-if="activity.NodeType === 0">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div
                                    v-for="(i, index13) in activity.ApprovalUserList"
                                    :key="index13"
                                  >
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}
                                  </div>
                                </div>
                                <i
                                  class="el-icon-user f14"
                                  style="color: #aaa"
                                />
                              </el-tooltip>
                              <span
                                class="p-left-10"
                              >{{
                                activity.ApprovalUserList[0].Name
                              }}
                                发起新的</span>
                            </div>
                            <div v-else class="flex">
                              <el-tooltip placement="bottom">
                                <div slot="content">
                                  <div
                                    v-for="(i,idn) in activity.ApprovalUserList"
                                    :key="idn"
                                  >
                                    {{ i.Name }}<br>
                                    {{ i.Mobile }}<br>
                                  </div>
                                </div>
                                <i
                                  class="el-icon-user f14"
                                  style="color: #aaa;line-height:20px"
                                />
                              </el-tooltip>
                              <div
                                v-if="activity.ApprovalUserList.length >= 1"
                                class="flex p-left-10 el-1"
                              >
                                {{ activity.ApprovalUserList[0].Name }}
                                <span
                                  v-if="activity.ApprovalUserList.length > 1"
                                  class="el-1"
                                >等人</span>
                                {{
                                  activity.ApprovalUserList[0]
                                    .ApprovaPersonStatusString
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="caozuo t-right p-right-5">
                    <el-button
                      size="mini"
                      @click="DownApprovalRecordList"
                    >导出审批日志</el-button>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="修改日志" name="second">
                <div>
                  <el-timeline style="padding: 5px">
                    <el-timeline-item
                      v-for="(activity, index) in RecordEditList"
                      :key="index"
                      :timestamp="activity.WriteTimeString"
                      placement="top"
                    >
                      <div class="card_bussness flex j-center column">
                        <div class="top flex column j-between p-lr-20">
                          <div
                            class="flex j-between min-border-bottom p-bottom-5"
                          >
                            <div style="color: #909399">
                              {{ activity.NickName }}
                            </div>
                            <!-- el-icon-s-order -->
                            <i class="el-icon-tickets" style="color: #096dcd" />
                          </div>
                          <div class="p-top-5 weight">
                            {{ activity.EditContent }}
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                  <div class="btn flex j-center a-center">
                    <el-button size="mini" @click="DownApprovalRecordList">导出修改日志</el-button>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
              <el-tab-pane label="留言" name="third">
                <div class="chat flex column">
                  <div id="content" class="content">
                    <div id="content_view" class="content_view p-lr-20">
                      <div
                        v-for="(chat, ina) in ChatList.MessageList"
                        :key="ina"
                      >
                        <!-- 左侧 别人 -->
                        <div
                          v-if="ChatList.UserID !== chat.UserID"
                          class="left_chat"
                        >
                          <div class="item_chat p-tb-10">
                            <div
                              class="p-tb-10 f12"
                              style="color: #999; text-align: left"
                            >
                              <i class="el-icon-user-solid" />
                              {{ chat.NickName }} {{ chat.WriteTimeString }}
                            </div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image
                                :src="chat.Message"
                                @click="showImage(chat.Message)"
                              />
                            </div>
                            <div
                              v-if="chat.MessageType"
                              class="item_content_message"
                            >
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                        <!-- 左侧 自己 -->
                        <div
                          v-if="ChatList.UserID === chat.UserID"
                          class="right_chat"
                        >
                          <div class="item_chat p-tb-10">
                            <div
                              class="p-tb-10 f12"
                              style="color: #999; text-align: right"
                            >
                              {{ chat.NickName }}{{ chat.WriteTimeString
                              }}<i class="el-icon-user-solid" />
                            </div>
                            <div v-if="!chat.MessageType" class="item_content">
                              <el-image
                                :src="chat.Message"
                                @click="showImage(chat.Message)"
                              />
                            </div>
                            <div
                              v-if="chat.MessageType"
                              class="item_content_message"
                            >
                              {{ chat.Message }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="send_view flex column">
                    <div class="input_view">
                      <el-input
                        v-model="input_value"
                        type="textarea"
                        :rows="2"
                        resize="none"
                        placeholder="点击发送消息"
                        maxlength="140"
                      />
                    </div>
                    <div class="btm flex a-center j-between p-lr-10">
                      <div>
                        <i class="el-icon-folder p-right-5" @click="selectFile">
                          <input
                            id="file"
                            ref="filElem"
                            style="
                              filter: alpha(opacity=0);
                              opacity: 0;
                              width: 0;
                              height: 0;
                            "
                            type="file"
                            class="upload-file"
                            @change="getFile"
                          >
                        </i>
                        <el-popover
                          placement="top"
                          trigger="click"
                          :width="50"
                          popper-class="test_pop_view"
                        >
                          <div>
                            <div
                              v-for="(i, n) in UserList"
                              :key="n"
                              class="cursor"
                              @click.stop="select_user(i)"
                            >
                              {{ i.NickName }}
                            </div>
                          </div>
                          <span slot="reference" class="cursor">@</span>
                        </el-popover>
                      </div>
                      <div>
                        <span
                          class="f12 p-right-10"
                          style="color: #ccc"
                        >{{ chat_value_length }} /140</span>
                        <span
                          class="weight f14 cursor"
                          style="color: #096dcd"
                          @click="sendMsg(false)"
                        >发送</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-else>
                  暂无权限
                </div> -->
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 审批弹窗 end -->
    <el-image-viewer
      v-if="showViewer"
      style="z-index: 9999"
      :url-list="MaterialFileList"
      :on-close="() => (showViewer = fasle)"
    />
    <!-- 选择物料库产品 添加到当前公司库-->
    <el-dialog class="DepartmentBox" title="选择产品" :visible.sync="addMaterialVisbel" width="800px" :close-on-click-modal="false">
      <div class="ChooseProducts">
        <el-form :inline="true" :model="addMaterialListParas" class="demo-form-inline">
          <el-form-item label="物料编号">
            <el-input v-model="addMaterialListParas.InvCode" placeholder="请输入物料编号" />
          </el-form-item>
          <el-form-item label="物料名称">
            <el-input v-model="addMaterialListParas.InvName" placeholder="请输入物料名称" />
          </el-form-item>
          <el-form-item label="品牌名称">
            <el-input v-model="addMaterialListParas.BrandName" placeholder="请输入品牌名称" />
          </el-form-item>
          <el-form-item label="规格型号">
            <el-input v-model="addMaterialListParas.Specification" placeholder="请输入规格型号" />
          </el-form-item>
          <el-form-item label="原厂编号">
            <el-input v-model="addMaterialListParas.ManuPartNum" placeholder="请输入原厂编号" />
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="addMateriallistCurrentChange(1)">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        v-loading="addMaterialloading"
        :header-cell-style="{background:'rgb(247,248,252)'}"
        :data="addMaterialList"
        tooltip-effect="dark"
        show-header
        border
      >
        <af-table-column
          prop="InvCode"
          label="物料编号"
        />
        <af-table-column
          prop="InvName"
          label="物料名称"
          show-overflow-tooltip
        />
        <af-table-column
          prop="NickName"
          label="物料简称"
          show-overflow-tooltip
        />
        <af-table-column
          prop="BrandName"
          label="品牌"
          show-overflow-tooltip
        />
        <af-table-column
          prop="MaterialTypeName"
          label="物料类型"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.MaterialTypeName }} / {{ scope.row.MaterialSubTypeName }}
          </template>
        </af-table-column>
        <af-table-column
          prop="Specification"
          label="规格/型号"
          show-overflow-tooltip
        />
        <af-table-column
          prop="StockUnitN"
          label="单位"
          show-overflow-tooltip
        />
        <af-table-column
          prop=""
          label="操作"
          show-overflow-tooltip
          fixed="right"
          width="70"
        >
          <template slot-scope="scope">
            <el-link type="primary" :underline="false" @click="conFirmAddoneMaterial_2(scope.row)">选择物料</el-link>
          </template>
        </af-table-column>
      </el-table>
      <div v-if="addMaterialList.length < 1 && addMaterialloading === false" style="color:red;text-align: center;" class="m-tb-20">请输入条件进行物料查询</div>
      <div v-if="addMaterialList.length > 0" class="pagination-container m-bottom-20">
        <el-pagination
          :total="addMateriallistSize"
          :current-page="addMaterialListParas.PageIndex"
          :page-size="addMaterialListParas.PageSize"
          :background="true"
          layout="prev, pager, next, jumper"
          @current-change="addMateriallistCurrentChange"
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addmaterial()">未找到物料，去新增</el-button>
      </div>
    </el-dialog>
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetFirstCampMateriaList" :list="tableListJson.productlist" name="productlist" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import productList from '@/minxin/product/productList.js'
export default productList
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/product.scss';
@import "@/style/business.scss";
</style>
<style lang='scss'>
</style>
