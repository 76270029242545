import { get, post } from './request'

export default {
  // 获取产品首营列表
  getFistProductList: data => post('/GetFirstCampMateriaList', data),
  // 编辑产品
  EditProductInfo: data => post('/SubmitFirstCampMaterialInfo', data),
  // 获取产品详情
  getProductInfo: data => get('/GetFirstCampMateriaDeatil', data),
  // 获取物料分类
  getInvTypeList: data => get('/basedata/MaterialInvType', data),
  // 获取物料类型
  getMaterialType: data => get('/basedata/MaterialTypeInfo_Saas', data),
  // 获取物料单位(暂无接口)
  getUnitList: data => get('/basedata/MaterialTypeInfo', data),
  // 获取原产地
  getSorceList: data => get('/basedata/MaterialSourceArea', data),
  // 获取存储条件
  getConditionList: data => get('/basedata/MaterialStorageCondition', data),
  // 获取部门接口
  getDepartList: data => get('/basedata/PermissionGroupInfo', data),
  // 获取人员接口
  getUserList: data => get('/basedata/UserInfo', data),
  // 获取人员信息
  getUserInfo: data => get('/GetUserInfo', data),
  // 删除产品
  deletProduct: data => get('/deletProduct', data),
  // 导出产品
  exportProduct: data => post('/ExportFirstCampMateriaInfoByUserField', data),
  // 禁用启用产品
  EnableProduct: data => post('/EditMaterialInfoState', data),
  // 获取证照类型
  getFileTypes: data => get('/GetCertificateType?CertificateType=2', data),
  // 保存证照
  SubmitMaterCertificateInfo: data => post('/SubmitMaterCertificateInfo', data),
  // 打包下载zip
  DownloadFirstCampZip: data => post('/DownloadFirstCampZip', data),
  // 获取品牌
  GetBrandListForFirstCamp: data => post('/GetBrandListForFirstCamp', data),
  // 删除产品证照
  DeleAddMaterCertificateInfo: data => post('/DeleAddMaterCertificateInfo', data),
  // 单个下载证照
  GetFirstCampFiles: data => get('/GetFirstCampFiles', data),
  // 删除产品
  DeleFirstCampMateriaInfo: data => get('/DeleFirstCampMateriaInfo', data),
  // 产品首营列表 --审批通过的数据
  GetMaterialRefCompanyList: data => post('/GetMaterialRefCompanyList', data),
  // 产品的详情 --审批通过的数据
  GetMaterialRefCompanyDeatil: data => get('/GetMaterialRefCompanyDeatil', data),
  // 导出产品 --审批通过的数据
  ExportMaterialRefCompanyInfoByUserField: data => post('/ExportMaterialRefCompanyInfoByUserField', data),
  // 删除产品证照 --审批过的
  DeleMaterCertificateInfo: data => get('/DeleMaterCertificateInfo', data),
  // 修改提交产品首营信息--已审批通过
  EditFirstCampmMaterialRefCompany: data => post('/EditFirstCampmMaterialRefCompany', data),
  // 更新产品首营状态信息--已审批通过
  EditMaterialInfoState: data => post('/EditMaterialInfoState', data),
  // 上传证照
  SubmitMaterCertificateByRefCompany: data => post('/SubmitMaterCertificateByRefCompany', data),
  // 查询物料库信息
  GetMaterialInfoList: data => get('/GetMaterialInfoList', data),
  // 将选中的物料添加到当前公司库（1：判断当前公司库 是否已经将物料发起首映 或已经首映过了
  MakeFirstFromMaterial: data => get('/MakeFirstFromMaterial', data),
  // 从产品列表中发起首营
  SaveFirstCampMaterialFromMaterialList: data => post('/SaveFirstCampMaterialFromMaterialList', data),
  // 上传证照
  UploadFirstOrMaterialCertificate: data => post('/UploadFirstOrMaterialCertificate', data)
}
